/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

import { MSafeAny } from '@mercadona/common/private';

export interface RouteToPreload {
  routePath: string;
  route: Route;
  load: Function;
}

@Injectable()
export class LazyLoaderService implements PreloadingStrategy {
  private routesToPreload: { [name: string]: RouteToPreload } = {};

  preload(route: Route, load: Function): Observable<MSafeAny> {
    if (this.isPreloadRoute(route)) {
      return load();
    }

    if (this.isOnDemandPreloadRoute(route)) {
      this.registerPreloadRoute(route, load);
      return of(null);
    }

    return of(null);
  }

  preloadRoute(path: string): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.isValidOnDemandPreloadRoute(path)) {
        const routeToPreload: RouteToPreload = this.routesToPreload[path];

        if (routeToPreload) {
          routeToPreload.load();
        }
      }
      resolve();
    });
  }

  registerPreloadRoute(route: Route, load: Function) {
    if (route.path) {
      this.routesToPreload[route.path] = {
        routePath: route.path,
        route,
        load
      };
    }
  }

  isPreloadRoute(route: Route) {
    return route.data && route.data.preload;
  }

  isOnDemandPreloadRoute(route: Route) {
    return route.data && route.path && route.data.onDemandPreload;
  }

  isValidOnDemandPreloadRoute(path: string) {
    return this.routesToPreload && this.routesToPreload[path];
  }
}
