import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/** LoadingService */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject: Subject<boolean> = new Subject();
  loadingSubject$ = this.loadingSubject.asObservable();

  enableLoading() {
    this.loadingSubject.next(true);
  }
  disableLoading() {
    this.loadingSubject.next(false);
  }
}
