import { Injectable } from '@angular/core';

import { AuthMethod } from '@interfaces/auth-method.interface';

import { MLocalStorageService, MSessionStorageService } from '@mercadona/core/storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private localStorageService: MLocalStorageService, private mSessionStorage: MSessionStorageService) {}

  public hasSession(): AuthMethod {
    const authMethodStorage: AuthMethod = this.localStorageService.get('authMethod') as AuthMethod;
    return authMethodStorage ?? null;
  }

  public isSessionVerified(): boolean {
    const session = this.hasSession();
    return session && session.verified;
  }

  initSession(method: string) {
    this.localStorageService.set('authMethod', { id: method, verified: false });
  }

  verifySession() {
    const authMethodStorage = this.hasSession();
    const authMethod: AuthMethod = authMethodStorage ?? null;
    if (authMethod) {
      authMethod.verified = true;
      this.localStorageService.set('authMethod', authMethod);
    }
  }

  public clearSession() {
    this.localStorageService.clear();
    this.mSessionStorage.clear();
  }
}
