export enum RequestErrorCodes {
  UNKNOWN_ERROR = 0,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  MAINTENANCE = 418,
  LOCKED = 423,
  UNAVAILABLE = 417,
  UPGRADE_REQUIRED = 426
}
