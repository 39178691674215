import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { documentBlur } from 'app/shared/utils';

import { MSafeAny } from '@mercadona/common/private';
import { MDialogConfig, MDialogRef, MDialogService } from '@mercadona/components/dialog';
import {
  MDialogAlertData,
  MDialogConfirmData,
  MDialogConfirmState
} from '@mercadona/components/dialog/models/dialog.model';
import { MSnackbarConfig, MSnackbarService } from '@mercadona/components/snackbar';
import { MTranslateService } from '@mercadona/core/translate';

import { defaultConfig } from './messenger.constants';

/** MessengerService should be return different dialog pop-ups on actions */
@Injectable()
export class MessengerService {
  /**
   * Class constructor
   *
   * @param mTranslate
   * @param mDialog
   * @param mSnackbar
   */
  constructor(
    private mTranslate: MTranslateService,
    private mDialog: MDialogService,
    private mSnackbar: MSnackbarService
  ) {}

  /**
   * Shows a toas with message
   *
   * @param Parameters
   * @param message
   * @param action
   * @param duration
   * @returns
   */
  showToast(message: Parameters<MTranslateService['translate']>, action?: string, duration: number = 3000) {
    const options: MSnackbarConfig = {
      message: this.mTranslate.translate(...message),
      action: action ? this.mTranslate.translate(action) : undefined,
      duration
      /*  marginBottom: `${window.innerHeight - 75}px` */
    };
    return this.mSnackbar.create(options);
  }

  /**
   * Returns an alert dialog message with button
   *
   * @param {Parameters<MTranslateService['translate']>} message
   * @param {string} button
   * @param {string | null} [info]
   * @returns {Promise<boolean>}
   */
  showAlert(message: Parameters<MTranslateService['translate']>, button: string, info?: string): Promise<boolean> {
    const dialogModeData: MDialogAlertData = {
      message: this.mTranslate.translate(...message),
      buttonLabel: this.mTranslate.translate(button),
      infoLabel: info ? this.mTranslate.translate(info) : undefined
    };

    return this.dialogControl(documentBlur(), this.mDialog.openAlert(dialogModeData));
  }

  /**
   * Returns a mDialog with confirmation actions
   *
   * @param {Parameters<MTranslateService['translate']>} message
   * @param {string} confirmButton
   * @param {string} cancelButton
   * @param {string | null} [info]
   * @param state
   * @param {MDialogConfirmState | null}
   * @returns {Promise<boolean>}
   */
  showConfirm(
    message: Parameters<MTranslateService['translate']>,
    confirmButton: string,
    cancelButton: string,
    info?: string,
    state?: MDialogConfirmState
  ): Promise<boolean> {
    const dialogConfirmDialogData: MDialogConfirmData = {
      message: this.mTranslate.translate(...message),
      confirmButtonLabel: this.mTranslate.translate(confirmButton),
      cancelButtonLabel: this.mTranslate.translate(cancelButton),
      infoLabel: info ? this.mTranslate.translate(info) : undefined,
      state
    };
    return this.dialogControl(documentBlur(), this.mDialog.openConfirm(dialogConfirmDialogData));
  }

  /**
   * Returns mDialog
   *
   * @template T
   * @param {ComponentType<T>} component
   * @param {MDialogConfig} [customConfig=defaultConfig] Default is `defaultConfig`
   * @returns
   */
  showCustom<T>(component: ComponentType<T>, customConfig: MDialogConfig = defaultConfig) {
    return this.dialogControl(documentBlur(), this.mDialog.open(component, customConfig));
  }

  /**
   * Returns a dialog
   *
   * @private
   * @template T
   * @param {MSafeAny} focus
   * @param {MDialogRef<T>} dialog
   * @returns {Promise<boolean>}
   */
  private dialogControl<T>(focus: MSafeAny, dialog: MDialogRef<T>): Promise<boolean> {
    return dialog
      .afterClosed()
      .pipe(tap(() => focus()))
      .toPromise();
  }
}
