import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LazyLoaderService } from '@services/lazy-loader.service';

import { UserSessionVerifierGuard } from './guards/user-session-verifier.guard';

// IMPORTANT: Do not use the "/error" route as it will be overwritten by the Core module. More info:
// https://mus.mercadona.com/39eafa15b/v/0/p/014302-page-error
// IMPORTANT: Do not use the "/monitoring_nginx" route as it will be overwritten by Nginx Configuration
// IMPORTANT: Do nout user the "/callback" as it will be overwritten by the Token module. More info:
// https://mus.mercadona.com/39eafa15b/v/0/p/043ae9-rutas-no-permitidas

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login-mode',
        pathMatch: 'full'
      },
      {
        path: 'login-mode',
        loadChildren: () => import('./pages/login-mode/login-mode-page.module').then((m) => m.LoginModePageModule),
        canActivate: [UserSessionVerifierGuard],
        data: { preload: true }
      }
    ]
  },

  {
    path: '**',
    redirectTo: ''
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: LazyLoaderService })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
