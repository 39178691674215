import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorRestModule } from '@components/dialogs/error-rest/error-rest.module';
import { LoadingModule } from '@components/loading/loading.module';
import { APP_CONFIG } from '@constants';
import { environment } from '@environment';
import { LazyLoaderService } from '@services/lazy-loader.service';
import { MessengerService } from '@services/messenger/messenger.service';
import { NgxMaskModule } from 'ngx-mask';

import { MDialogService } from '@mercadona/components/dialog';
import { MSnackbarService } from '@mercadona/components/snackbar';
import { MLoggerModule } from '@mercadona/core/logger';
import { MPlatformModule } from '@mercadona/core/platform';
import { MStorageModule } from '@mercadona/core/storage';
import { MTelemetryModule } from '@mercadona/core/telemetry';
import { MTranslateModule, MTranslateService } from '@mercadona/core/translate';
import { MErrorHandlerModule } from '@mercadona/core-ui/error-handler';
import { MPageErrorModule } from '@mercadona/core-ui/page-error';
import { MPageNotFoundModule } from '@mercadona/core-ui/page-not-found';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserSessionVerifierGuard } from './guards/user-session-verifier.guard';
import { LoadingInterceptorService } from './interceptors/api-loading.interceptor';
import { ApiInterceptor } from './interceptors/api.interceptor.service';
import { LocaleInterceptor } from './interceptors/locale.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MErrorHandlerModule.forRoot(),
    MTranslateModule.forRoot(APP_CONFIG.language),
    NgxMaskModule.forRoot(),
    MLoggerModule.forRoot({
      logLevel: environment.logLevel
    }),
    MTelemetryModule.forRoot({
      url: environment.telemetryConfig.url,
      traces: environment.telemetryConfig.traces
    }),
    MPlatformModule.forRoot({
      appName: APP_CONFIG.appName,
      environment: environment.env
    }),
    MPageNotFoundModule,
    MPageErrorModule.forRoot(),
    LoadingModule,
    MStorageModule.forRoot(),
    ErrorRestModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    LazyLoaderService,
    MessengerService,
    MDialogService,
    MSnackbarService,
    MTranslateService,
    UserSessionVerifierGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static _injector: Injector;
  constructor(private injector: Injector) {
    // eslint-disable-next-line no-underscore-dangle
    AppModule._injector = this.injector;
  }
}
