import { MLoggerLevel } from '@mercadona/core/logger';
import { MPlatformEnvironment } from '@mercadona/core/platform';
import { MTelemetryConfig, MTraces } from '@mercadona/core/telemetry';
import { MEnviroment } from '@mercadona/core/utils/environment';

/**
 * Do not duplicate this file into environment.dev.ts, environment.itg.ts, ... Define here ONLY localhost environment
 * variables.
 */
/** For dev,itg,pre,pro environments copy this content to the chart files charts/env/values-<env>.yaml */
const localEnvironment = {
  production: false,
  logLevel: MLoggerLevel.TRACE,
  env: 'local' as MPlatformEnvironment,
  telemetryConfig: {
    url: 'https://otelcol-front.dev.gcp.mercadona.com/v1/traces',
    traces: MTraces.DEFAULT
  } as MTelemetryConfig,
  configToken: {
    adfs: {
      clientId: '2660748d-8951-497b-9e41-8761d0c9b388',
      stsServer: 'https://fed.itgmercadona.com/adfs',
      knownAuthorities: ['https://fed.itgmercadona.com/adfs'],
      redirectUri: 'http://localhost:4200/porag/main-menu',
      resource: 'api://mercadona/audience/services/porag/v2',
      postLogoutUrl: 'http://localhost:4200/porag/',
      scope: ['openid'],
      restServices: [
        {
          url: 'https://api.itg.mercadona.com/technical/porag/v2',
          resource: 'api://mercadona/audience/services/porag/v2',
          scope: ['openid']
        }
      ]
    },
    azure: {
      clientId: '0ebb4419-2a88-43fe-bc78-bf12a728360b',
      authority: 'https://boteprovb2c.b2clogin.com/boteprovb2c.onmicrosoft.com/B2C_1_Porag_Login_Proveedor',
      knownAuthorities: ['https://boteprovb2c.b2clogin.com/boteprovb2c.onmicrosoft.com/B2C_1_Porag_Login_Proveedor'],
      redirectUri: 'http://localhost:4200/porag/main-menu',
      postLogoutUrl: 'http://localhost:4200/porag/',
      scope: ['0ebb4419-2a88-43fe-bc78-bf12a728360b'],
      restServices: [
        {
          url: 'https://api.itg.mercadona.com/technical/porag/v2',
          resource: 'https://boteprovb2c.onmicrosoft.com/mercadona/audience/services/porag/v2',
          scope: ['openid']
        }
      ]
    }
  }
};

/**
 * Description placeholder
 *
 * @type {any}
 */
const envInstance = new MEnviroment('porag-front-main', localEnvironment);

/**
 * Description placeholder
 *
 * @type {any}
 */
export const environment = envInstance.environment;
