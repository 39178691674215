import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { MTranslateService } from '@mercadona/core/translate';

/**
 * Intercepts all requests and add the locale or language param.
 *
 * @class LocaleInterceptor
 * @typedef {LocaleInterceptor}
 * @implements {HttpInterceptor}
 */
@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of LocaleInterceptor.
   *
   * @class
   * @param {MTranslateService} mTranslateSvc
   */
  constructor(private mTranslateSvc: MTranslateService) {}

  /**
   * Intercepts the request.
   *
   * @param {HttpRequest<unknown>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<unknown>>}
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.localizeRequest(request).pipe(switchMap((localizedRequest) => next.handle(localizedRequest)));
  }

  /**
   * Add the param of localeLanguage or idioma when the url match with the ccp/v1 or not.
   *
   * @private
   * @param {HttpRequest<unknown>} request
   * @returns {Observable<HttpRequest<unknown>>}
   */
  private localizeRequest(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    return this.mTranslateSvc.activeLocale$.pipe(
      take(1),
      map((locale) => {
        const params = request.params.set('locale', locale);
        return request.clone({ params });
      })
    );
  }
}
