import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  Data,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router
} from '@angular/router';
import { filter, tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InMemoryStorageService } from '@services/in-memory-storage.service';
import { LocalStorageService } from '@services/local-storage.service';

import { MTranslateService } from '@mercadona/core/translate';
import { WINDOW } from '@mercadona/core/utils/tokens';
import { MIconService } from '@mercadona/icons';
import { calendar3Svg, cogSvg, envelopeSvg } from '@mercadona/icons/svg';

import { authBasedRoutes } from './shared/core/auth-based-routes';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showBreadcrumb = true;
  private activatedRouteSnapshot: ActivatedRouteSnapshot | null = null;

  constructor(
    private router: Router,
    private mIconService: MIconService,
    private imStorage: InMemoryStorageService,
    private localStorageService: LocalStorageService,
    private titleService: Title,
    private mTraslateService: MTranslateService,
    @Inject(WINDOW) private window: Window
  ) {
    this.titleService.setTitle(this.mTraslateService.translate('HEADER.TITLE'));
    this.mIconService.registerIcon(calendar3Svg, envelopeSvg, cogSvg);
    this.setInitAuthenticationMethod();
  }

  ngOnInit(): void {
    if (this.hasServerError()) {
      this.router.navigate(['']);
      return;
    }
    this.setSectionTitle();
  }

  setInitAuthenticationMethod() {
    const session = this.localStorageService.hasSession();
    if (session) {
      const authenticatedConfig = this.router.config;
      authenticatedConfig.splice(1, this.router.config.length, ...authBasedRoutes[session.id]);
      this.router.resetConfig(authenticatedConfig);
    }
  }

  setSectionTitle() {
    this.listenRouterEvents();
  }

  setPageTitle(e: ActivatedRouteSnapshot) {
    const title = e.data.pageTitle;
    if (title) {
      this.imStorage.setPageTitle(title.toUpperCase().replace(/-/g, '_'));
    }
    return true;
  }

  listenRouterEvents(): void {
    let activatedRouteSnapshotAux: ActivatedRouteSnapshot | null = null;
    this.router.events
      .pipe(
        untilDestroyed(this),
        tap((event) => {
          if (event instanceof NavigationCancel || event instanceof NavigationError) {
            activatedRouteSnapshotAux = null;
          }
        }),
        filter(this.filterRouterEvents),
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.activatedRouteSnapshot = activatedRouteSnapshotAux;
            if (this.activatedRouteSnapshot) {
              this.setPageTitle(this.activatedRouteSnapshot);
            }
            activatedRouteSnapshotAux = null;
          } else if (event instanceof ActivationEnd && activatedRouteSnapshotAux === null) {
            activatedRouteSnapshotAux = event.snapshot;
          }
        })
      )
      .subscribe();
  }

  public get routeData(): Data | undefined {
    return this.activatedRouteSnapshot?.data;
  }

  private filterRouterEvents(event: Event): event is NavigationEnd | ActivationEnd {
    return event instanceof NavigationEnd || event instanceof ActivationEnd;
  }

  private hasServerError() {
    return this.window.location.href.includes('error=server_error');
  }
}
