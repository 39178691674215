import { MDialogConfig } from '@mercadona/components/dialog';

const defaultDimensions: { width: string; height: string } = {
  width: '30%',
  height: '50%'
};

const defaultConfig: MDialogConfig = {
  autoFocus: true, //false,
  width: defaultDimensions.width,
  height: defaultDimensions.height
};

enum DialogStates {
  CLOSE = 0,
  CANCEL = 1,
  ACTION = 2
}

export { defaultDimensions, defaultConfig, DialogStates };
