import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MButtonModule } from '@mercadona/components/button';
import { MDialogModule } from '@mercadona/components/dialog';
import { MTranslateModule } from '@mercadona/core/translate';

import { ErrorRestComponent } from './error-rest.component';

/** ErrorRestModule */
@NgModule({
  declarations: [ErrorRestComponent],
  imports: [CommonModule, MButtonModule, MDialogModule, MTranslateModule],
  exports: [ErrorRestComponent]
})
export class ErrorRestModule {}
