import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingService } from '@services/loading.service';

/** LoadingComponent represents a loading spinner on calls */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements AfterViewInit, OnDestroy {
  loadingSubscription: Subscription = new Subscription();
  /**
   * Class constructor
   *
   * @param loadingStatus
   * @param elmRef
   * @param changeDetectorRef
   */
  constructor(
    private loadingStatus: LoadingService,
    private elmRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.elmRef.nativeElement.style.display = 'none';
    this.loadingSubscription = this.loadingStatus.loadingSubject$.subscribe((status: boolean) => {
      this.elmRef.nativeElement.style.display = status ? 'block' : 'none';
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
