import { AbstractControl, ValidationErrors } from '@angular/forms';

import { environment } from '@environment';
import { LocalStorageService } from '@services/local-storage.service';

export const DEFAULT_PENDING_VALUE = 'Pdte';
export const ARRAY_HOURS: string[][] = [
  ['00:00 - 01:00', '01:00 - 02:00', '02:00 - 03:00', '03:00 - 04:00', '04:00 - 05:00', '05:00 - 06:00'],
  ['06:00 - 07:00', '07:00 - 08:00', '08:00 - 09:00', '09:00 - 10:00', '10:00 - 11:00', '11:00 - 12:00'],
  ['12:00 - 13:00', '13:00 - 14:00', '14:00 - 15:00', '15:00 - 16:00', '16:00 - 17:00', '17:00 - 18:00'],
  ['18:00 - 19:00', '19:00 - 20:00', '20:00 - 21:00', '21:00 - 22:00', '22:00 - 23:00', '23:00 - 24:00']
];
export const ARRAY_ORIGIN_DESTINY_HOURS: { origin: string; destiny: string }[] = [
  { origin: '00:00:00.000Z', destiny: '05:00:00.000Z' },
  { origin: '06:00:00.000Z', destiny: '11:00:00.000Z' },
  { origin: '12:00:00.000Z', destiny: '17:00:00.000Z' },
  { origin: '18:00:00.000Z', destiny: '23:00:00.000Z' }
];

/**
 * Prevent Angular to throw ExpressionChangedAfterItHasBeenCheckedError when a mDialog steals focus
 *
 * @returns {Function} A function to focus again the blurred `HTMLElement`
 */
export function documentBlur(): () => void {
  const activeElement = document.activeElement;
  if (activeElement instanceof HTMLElement && activeElement !== document.body) {
    activeElement.blur();
    return () => {
      if (document.body.contains(activeElement)) {
        activeElement.focus();
      }
    };
  }
  return () => undefined;
}

/**
 * Gets a REST service URL based on session ID and index.
 *
 * @param {LocalStorageService} localStorageService - Local storage service instance.
 * @param {number} [index=0] - Index of the rest service (default: 0).
 * @returns {string} The REST service URL if the session exists, otherwise an empty string.
 */
export function getRestService(localStorageService: LocalStorageService, index: number = 0): string {
  const session = localStorageService.hasSession();
  if (!session || !session.id) {
    return '';
  }
  const restServices = environment.configToken[session.id].restServices;
  return restServices[index].url;
}

/**
 * @param previousValue
 * @param currentValue
 */
export function calculateMaxDate(previousValue: string, currentValue: string) {
  const previousDate = new Date(previousValue);
  const currentDate = new Date(currentValue);

  return previousDate >= currentDate ? previousValue : currentValue;
}
/** @param control */
export function timepickerRequiredValidator(control: AbstractControl): ValidationErrors | null {
  const isNullOrUndefined = (value: number): boolean => value === null || value === undefined;

  if (isNullOrUndefined(control.value?.hours) || isNullOrUndefined(control.value?.minutes)) {
    return { required: true };
  }
  return null;
}

/**
 * Handles the input event and modifies the value of the input element.
 * Removes any non-alphanumeric characters and converts the value to uppercase.
 *
 * @param {Event} event - The input event object.
 */
export function onInput(event: Event): void {
  const eventTarget = event.target as HTMLInputElement;
  eventTarget.value = eventTarget.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
}
