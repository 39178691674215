import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MSafeAny } from '@mercadona/common/private';

@Injectable({
  providedIn: 'root'
})
export class InMemoryStorageService {
  private readonly map: Map<string, string | null> = new Map();

  private pageTitleSubject = new BehaviorSubject<string | undefined>(undefined);
  pageTitleSubject$ = this.pageTitleSubject.asObservable();

  public clear(): void {
    this.map.clear();
  }

  public get(key: string): MSafeAny | null {
    const data: MSafeAny = this.map.get(key);
    if (this.isDataUndefined(data)) {
      return null;
    }

    return JSON.parse(data);
  }

  public remove(key: string): void {
    this.map.delete(key);
  }

  public set(key: string, value: MSafeAny): void {
    let data: string | null = null;
    if (this.canFormat(value)) {
      data = JSON.stringify(value);
    }

    this.map.set(key, data);
  }

  isDataUndefined(data: MSafeAny) {
    return data === undefined || data === null || data.trim() === '';
  }

  canFormat(value: MSafeAny) {
    return value !== undefined && value !== null && (typeof value !== 'string' || value.trim() !== '');
  }

  public setPageTitle(page: string | undefined) {
    this.pageTitleSubject.next(page);
  }
}
