import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { LocalStorageService } from '@services/local-storage.service';

@Injectable()
export class UserSessionVerifierGuard implements CanActivate {
  constructor(private router: Router, private localStorageService: LocalStorageService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.localStorageService.isSessionVerified()) {
      return this.router.createUrlTree(['/main-menu']);
    }
    this.localStorageService.clearSession();
    return true;
  }
}
