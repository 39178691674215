import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingService } from '@services/loading.service';

import { MSafeAny } from '@mercadona/common/private';

/**
 * Intercepts all the requests and shows the loading spinner.
 *
 * @class LoadingInterceptorService
 * @typedef {LoadingInterceptorService}
 * @export
 * @implements {HttpInterceptor}
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptorService implements HttpInterceptor {
  /** @type {number} */
  activeRequests: number = 0;

  /**
   * Creates an instance of LoadingInterceptorService.
   *
   * @class
   * @param {LoadingService} loadingService
   */
  constructor(private loadingService: LoadingService) {}

  /**
   * Intercepts all the requests and call the spinner.
   *
   * @param {HttpRequest<MSafeAny>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<MSafeAny>>}
   */
  intercept(request: HttpRequest<MSafeAny>, next: HttpHandler): Observable<HttpEvent<MSafeAny>> {
    let params: HttpParams = request.params;
    const ignoreSpinner = params.has('$spinner');
    if (!ignoreSpinner) {
      if (this.activeRequests === 0) {
        this.loadingService.enableLoading();
      }

      this.activeRequests++;
    } else {
      params = params.delete('$spinner');
      request = request.clone({
        params
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!ignoreSpinner) {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingService.disableLoading();
          }
        }
      })
    );
  }
}
