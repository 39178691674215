/** ErrorRest */
export interface ErrorRest {
  error: {
    code: string;
    description: string;
    details?: string[];
  };
}

/**
 * Checks if a value represents an error rest response.
 *
 * @param {ErrorRest} value - The value to check.
 * @returns {boolean} True if the value represents an error rest response, false otherwise.
 */
export function isLikeErrorRest(value: ErrorRest): boolean {
  return Boolean(value && value.error && value.error.code && value.error.description);
}
