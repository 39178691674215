import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MSpinnerModule } from '@mercadona/components/spinner';
import { MTranslateModule } from '@mercadona/core/translate';

import { LoadingComponent } from './loading.component';

/** LoadingModule */
@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, MSpinnerModule, MTranslateModule],
  exports: [LoadingComponent]
})
export class LoadingModule {}
