import { Component, Inject } from '@angular/core';

import { ErrorRest } from '@models/error-rest.model';

import { MDialogRef, M_DIALOG_DATA, M_DIALOG_REF } from '@mercadona/components/dialog';

/**
 * Represents the error rest component
 *
 * @class ErrorRestComponent
 * @typedef {ErrorRestComponent}
 */
@Component({
  selector: 'app-error-rest',
  templateUrl: './error-rest.component.html',
  styleUrls: ['./error-rest.component.scss']
})
export class ErrorRestComponent {
  /**
   * Return the data error code
   *
   * @type {any}
   * @public
   * @readonly
   */
  public get code() {
    return this.data.error.code;
  }

  /**
   * Return the error description
   *
   * @type {any}
   * @public
   * @readonly
   */
  public get description() {
    return this.data.error.description;
  }

  /**
   * Return the error details
   *
   * @type {any}
   * @public
   * @readonly
   */
  public get details() {
    return this.data.error.details;
  }

  /**
   * Creates an instance of ErrorRestComponent.
   *
   * @class
   * @param {MDialogRef<ErrorRestComponent>} [ref] Ref
   * @param {ErrorRest} [data] Data
   */
  constructor(
    @Inject(M_DIALOG_REF) public ref: MDialogRef<ErrorRestComponent>,
    @Inject(M_DIALOG_DATA) public data: ErrorRest
  ) {}

  /**
   * Description placeholder
   *
   * @param {number} [_index] Index
   * @param {string} [item] Item
   * @returns {string} Returns an string item
   */
  trackByDefault(_index: number, item: string): string {
    return item;
  }
}
