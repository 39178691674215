import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorRest, isLikeErrorRest } from '@models/error-rest.model';
import { MessengerService } from '@services/messenger/messenger.service';
import { ErrorRestComponent } from 'app/components/dialogs/error-rest/error-rest.component';
import { isArray, isNil } from 'lodash-es';

import { MSafeAny } from '@mercadona/common/private';

import { RequestErrorCodes } from '../enums/request-errors.enum';

/**
 * Intercept all the request.
 *
 * @class ApiInterceptor
 * @typedef {ApiInterceptor}
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of ApiInterceptor.
   *
   * @class
   * @param {MessengerService} messengerSvc
   */
  constructor(private messengerSvc: MessengerService) {}

  /**
   * Intercepts all requests.
   *
   * @param {HttpRequest<MSafeAny>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<MSafeAny>>}
   */
  intercept(request: HttpRequest<MSafeAny>, next: HttpHandler): Observable<HttpEvent<MSafeAny>> {
    const ignoreDialog = Boolean(request.params.has('$dialog'));
    const body = request.body;
    let params = request.params;

    for (const key of params.keys()) {
      if (request.params.getAll(key)?.some((value) => isNil(value))) {
        params = params.delete(key);
      }
    }

    if (body) {
      this.removeNullValues(body);
      request = request.clone({ body });
    }

    if (ignoreDialog) {
      params = params.delete('$dialog');
    }

    request = request.clone({ params });

    return next.handle(request).pipe(catchError((error) => this.handleError(error, ignoreDialog)));
  }

  private removeNullValues(obj: MSafeAny) {
    for (const prop in obj) {
      if (typeof obj[prop] !== 'boolean' && !obj[prop]) {
        delete obj[prop];
      } else if (typeof obj[prop] === 'object') {
        this.removeNullValues(obj[prop]);
      } else if (isArray(obj[prop])) {
        obj[prop].forEach((value: MSafeAny) => this.removeNullValues(value));
      }
    }
  }

  private handleError(error: HttpErrorResponse, ignoreDialog: boolean): Observable<MSafeAny> {
    const body = error.error;
    if (isLikeErrorRest(body) && !ignoreDialog) {
      this.showErrorRest(body);
      return of(error);
    } else if (!ignoreDialog) {
      const response = this.isHttpErrorResponseJson(error);
      if (response) {
        return of(error);
      }
    }

    switch (error.status) {
      case RequestErrorCodes.UNKNOWN_ERROR:
        return this.handleUnknown(error);
      default:
        if (error instanceof TimeoutError) {
          return this.handleRxTimeout(error);
        }
    }

    return throwError(() => error);
  }

  /**
   * Handle the handleUnknown errors.
   *
   * @private
   * @param {HttpErrorResponse} error
   * @returns {Observable<HttpErrorResponse>}
   */
  private handleUnknown(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.showDefaultAlert(error.status);
    return of(error);
  }

  /**
   * Handle errors.
   *
   * @private
   * @param {HttpErrorResponse} error
   * @returns {Observable<HttpErrorResponse>}
   */
  private handleRxTimeout(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.showDefaultAlert(error.status);
    return of(error);
  }

  /**
   * Shows the default alert.
   *
   * @async
   * @private
   * @param {number} [status=0] Default is `0`
   * @returns {Promise<boolean>}
   */
  private async showDefaultAlert(status: number = 0): Promise<boolean> {
    const statusString = status.toString().toUpperCase();
    return await this.messengerSvc.showAlert(
      [`ERROR_MESSAGES.${statusString}`],
      'DIALOGS.CLOSE_TOAST',
      'DIALOGS.ALERT'
    );
  }

  /**
   * Show the error rest.
   *
   * @async
   * @private
   * @param {ErrorRest} data
   * @returns {Promise<boolean>}
   */
  private async showErrorRest(data: ErrorRest): Promise<boolean> {
    return await this.messengerSvc.showCustom(ErrorRestComponent, {
      data,
      width: '50%',
      maxWidth: 512,
      minWidth: 350
    });
  }

  private isHttpErrorResponseJson(error: HttpErrorResponse): boolean {
    if (!error.error) {
      return false;
    }

    let value = error.error;
    try {
      value = JSON.parse(value);
      this.showErrorRest(value);
      return true;
    } catch (e) {
      return false;
    }
  }
}
