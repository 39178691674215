<m-dialog-card [titleLabel]="description" [subtitle]="code" [state]="'destructive'" [hasHeaderIcon]="false">
  <div id="error-rest-details" *ngIf="details">
    <p *ngFor="let detail of details; trackBy: trackByDefault">{{ detail }}</p>
  </div>
  <m-dialog-card-actions>
    <button type="button" m-button [color]="'base'" (click)="ref.close()">
      <span>{{ 'DIALOGS.ACCEPT' | mTranslate }}</span>
    </button>
  </m-dialog-card-actions>
</m-dialog-card>
