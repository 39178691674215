import { Routes } from '@angular/router';

import { UserSessionVerifierGuard } from '../../guards/user-session-verifier.guard';

const adfsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login-mode',
        pathMatch: 'full'
      },
      {
        path: 'login-mode',
        loadChildren: () => import('@pages/login-mode/login-mode-page.module').then((m) => m.LoginModePageModule),
        canActivate: [UserSessionVerifierGuard],
        data: { preload: true }
      },
      {
        path: 'main-menu',
        loadChildren: () => import('@pages/main-menu/main-menu-page.adfs.module').then((m) => m.MainMenuPageModule)
      }
    ]
  },

  {
    path: '**',
    redirectTo: ''
  }
];

const azureRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login-mode',
        pathMatch: 'full'
      },
      {
        path: 'login-mode',
        loadChildren: () => import('@pages/login-mode/login-mode-page.module').then((m) => m.LoginModePageModule),
        canActivate: [UserSessionVerifierGuard],
        data: { preload: true }
      },
      {
        path: 'main-menu',
        loadChildren: () =>
          import('@pages/main-menu/main-menu-page.azure.module').then((m) => m.MainMenuPageAzureModule),
        data: { preload: false }
      }
    ]
  },

  {
    path: '**',
    redirectTo: ''
  }
];

const anonymousRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login-mode',
        pathMatch: 'full'
      },
      {
        path: 'login-mode',
        loadChildren: () => import('@pages/login-mode/login-mode-page.module').then((m) => m.LoginModePageModule),
        canActivate: [UserSessionVerifierGuard],
        data: { preload: true }
      }
    ]
  },

  {
    path: '**',
    redirectTo: ''
  }
];
export const authBasedRoutes: { adfs: Routes; azure: Routes; anonymous: Routes } = {
  adfs: adfsRoutes,
  azure: azureRoutes,
  anonymous: anonymousRoutes
};
